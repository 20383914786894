import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Twitter, Language, Instagram } from '@mui/icons-material';

import config from '../assets/config.json';
import { Stack } from '@mui/material';

const pages = [
    <Twitter sx={{ padding: 0, margin: 0 }} />,
    <Language sx={{ padding: 0, margin: 0 }} />,
    <Instagram sx={{ padding: 0, margin: 0 }} />
];

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const FooterBar = (props) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const partner = props.partner;
    const provider = config.providers[config.providers[partner.provider] ? partner.provider : 0];
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const socialLinks = [

        {
            name: "website",
            icon: Language,
            link: props.partner.website ? props.partner.website : undefined,
            isPresent: props.partner.website ? true : false
        },
        {
            name: "twitter",
            icon: Twitter,
            link: props.partner.twitter ? `https://www.twitter.com/${props.partner.twitter}` : undefined,
            isPresent: props.partner.twitter ? true : false
        },
        {
            name: "instagram",
            icon: Instagram,
            link: props.partner.instagram ? `https://www.instagram.com/${props.partner.instagram}` : undefined,
            isPresent: props.partner.instagram ? true : false
        }

    ]

    const PoweredBy = () => {
        return (
            <>
                <Typography
                    variant='h6'
                    noWrap
                    component="span"
                    color={props.lightMode ? "rgba(0,10,30,.7)" : "rgba(255,255,255,0.9)"}

                >
                    {props.partner.name}
                </Typography>
                <Typography
                    noWrap
                    component="span"
                    fontSize='small'
                    color={props.lightMode ? "rgba(0,10,30,.7)" : "rgba(255,255,255,0.9)"}
                >
                    powered by {" "}
                    <Button
                        onClick={
                            () => {
                                window.open(provider.website);
                            }
                        }
                        sx={{
                            textTransform: 'initial',
                            m: 0,
                            p: 0,
                            px: 0.5,
                            verticalAlign: 'baseline',
                            fontWeight: "700",
                            textDecoration: "none",
                            fontSize: "13px",
                            color:
                                props.lightMode
                                    ?
                                    "rgba(0,10,30,.7)"
                                    :
                                    "rgba(255,255,255,0.9)"
                        }}
                    >
                        {provider.name}
                    </Button>

                </Typography>
            </>
        );
    }

    return (
        <AppBar position="fixed"
            sx={{
                top: 'auto',
                bottom: '0',
                background: props.lightMode ? "rgba(255,255,255,0.9)" : "rgba(0,10,30,.7)"
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Stack sx={{ m: 0, display: { xs: 'none', md: 'flex' } }}>
                        <PoweredBy />
                    </Stack>



                    <Stack sx={{ flexGrow: 1, m: 0, display: { xs: 'flex', md: 'none' } }}>
                        <PoweredBy />
                    </Stack>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' } }}>


                        <Box
                            sx={{ display: { xs: 'none', md: 'flex' } }}
                        >
                            {socialLinks.map((social) => {
                                if (social.isPresent) {
                                    return (
                                        <Button
                                            key={social.name}
                                            onClick={() => window.open(social.link)
                                            }
                                            sx={{
                                                my: 2,
                                                color: props.lightMode ? "rgba(0,0,0,.8)" : "rgba(255,255,255,.8)",
                                                display: 'block'
                                            }}
                                        >
                                            <social.icon />
                                        </Button>
                                    );
                                }

                            })}
                        </Box>
                        <Box
                            onClick={
                                () => {
                                    window.open(provider.website);
                                }
                            }
                            sx={{
                                flexGrow: 0,
                                marginTop: " 10px",
                                display: { xs: 'flex', sm: 'flex' }

                            }}
                        >
                            <img
                                style={{
                                    maxHeight: "40px",
                                    padding: 2
                                }}
                                src={
                                    props.lightMode ? config.providers[props.partner.provider].icon
                                        :
                                        config.providers[props.partner.provider].iconDark
                                }
                            />
                        </Box>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default FooterBar;