import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { hot } from 'react-hot-loader';
import screenfull from 'screenfull';

//
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

//
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import FastForwardRounded from '@mui/icons-material/FastForwardRounded';
import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';

//
import MHidden from './Components/MHidden';
import ReactPlayer from 'react-player';
import { AppBar, Chip, CircularProgress, Divider, Fab, Grid, Toolbar } from '@mui/material';
//
import './css/player.css';
import './css/waveform.css';
import { borderRadius } from '@mui/system';
import { CloseFullscreenRounded, KeyboardArrowDown, KeyboardArrowUp, Minimize, MinimizeSharp, OpenInFullRounded, PlayArrowTwoTone, SkipNext, SkipPrevious } from '@mui/icons-material';



/*
import './reset.css'
import './defaults.css'
import './range.css'
import './App.css'

import { version } from '../../package.json'


import Duration from './Duration'
*/


class App extends Component {



    state = {
        url: null,
        pip: false,
        playing: false,
        controls: false,
        light: false,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
        isFullscreen: screenfull.isFullscreen,
        mainIconColor: "#fff"
    }

    load = url => {
        this.setState({
            url,
            played: 0,
            loaded: 0,
            pip: false
        })
    }


    handlePlayPause = () => {
        console.log("HANDLE PLAY PAUSE", this.state.playing);
        this.setState({ playing: !this.state.playing })
        this.props.setPlay(!this.props.play);
    }

    handleStop = () => {
        this.setState({ url: null, playing: false })
        this.props.setPlay(false);
    }

    handleToggleControls = () => {
        const url = this.state.url
        this.setState({
            controls: !this.state.controls,
            url: null
        }, () => this.load(url))
    }

    handleToggleLight = () => {
        this.setState({ light: !this.state.light })
    }

    handleToggleLoop = () => {
        this.setState({ loop: !this.state.loop })
    }

    handleVolumeChange = e => {
        this.setState({ volume: parseFloat(e.target.value) })
    }

    handleToggleMuted = () => {
        this.setState({ muted: !this.state.muted })
    }

    handleSetPlaybackRate = e => {
        this.setState({ playbackRate: parseFloat(e.target.value) })
    }

    handleOnPlaybackRateChange = (speed) => {
        this.setState({ playbackRate: parseFloat(speed) })
    }

    handleTogglePIP = () => {
        this.setState({ pip: !this.state.pip })
    }

    handlePlay = () => {
        console.log('onPlay');
        console.log("HANDLE PLAY", this.state.playing);
        this.props.setPlay(true);
        this.setState({ playing: true })
    }
    handleError = () => {
        !this.props.isReady && this.props.setIsReady(true);
        this.setState({ playing: this.props.play });
    }

    handleEnablePIP = () => {
        console.log('onEnablePIP')
        this.setState({ pip: true })
    }

    handleDisablePIP = () => {
        console.log('onDisablePIP')
        this.setState({ pip: false })
    }

    handlePause = () => {
        console.log('onPause');
        console.log("HANDLE PAUSE", this.state.playing);
        this.setState({ playing: false })
        this.props.setPlay(false);
    }

    handleSeekMouseDown = e => {
        this.setState({ seeking: true })
    }

    handleSeekChange = e => {
        this.setState({ played: parseFloat(e.target.value) })
    }

    handleSeekMouseUp = e => {
        this.setState({ seeking: false })

        console.log("prefloat", e.target.value);
        console.log("float", parseFloat(e.target.value));
        this.player.seekTo(parseFloat(e.target.value), 'fraction');
    }

    handleProgress = state => {
        console.log('onProgress', state);
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    handleEnded = () => {
        console.log('onEnded');
        this.setState({ playing: false /*this.state.loop*/ });
        this.props.nextArrow();
    }
    handleNext = () => {
        console.log("NEXT");
        this.props.nextArrow();
        this.props.isBuffer();
        this.handlePause();
        // this.props.setPlay(false);
        //this.setState({ playing: false })
    }

    handlePrev = () => {
        this.props.prevArrow();
        this.handlePause();
        this.props.isBuffer();
        // this.setState({ playing: false })
    }

    handleDuration = (duration) => {
        console.log('onDuration', duration)
        this.setState({ duration })
    }

    handleClickFullscreen = () => {

        /*const t = this.state.isFullscreen;
        if (t) {

        } else {*/
        screenfull.request(findDOMNode(this.viewa));
        //}
        //this.setState({ isFullscreen: !t });
    }

    renderLoadButton = (url, label) => {
        return (
            <button onClick={() => this.load(url)}>
                {label}
            </button>
        )
    }

    ref = player => {
        this.player = player
    }
    ref2 = viewa => {
        this.viewa = viewa
    }



    render() {
        const { url, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip, isFullscreen } = this.state
        const SEPARATOR = ' · '
        const formatDuration = (value) => {
            const minute = Math.floor(value / 60);
            const secondLeft = parseInt(value - minute * 60);
            return `${minute}:${(secondLeft <= 9 ? `0${secondLeft}` : secondLeft)}`;
        }

        /* const FullPlayerCard = () => {
             return (
                 <>
                     <Card
                         className="playerCard"
                         sx={{
                             backdropFilter: "blur(80)",
                             borderRadius: "20px"
                         }}
                         style={{
                             margin: 0,
                             backgroundImage: `url(${this.props.meditation.scene})`,
                             backgroundSize: 'cover',
                             backgroundRepeat: 'no-repeat',
                             border: this.props.lightMode ? "3px solid black" : "3px solid gold",
                             outline: "4px solid rgba(0,0,0,.2)",
                             outlineOffset: "-7px",
                             borderRadius: "20px",
                             maxWidth: "98vw",
                             padding: 'auto',
                             margin: 'auto',
                             paddingBottom: 2
                         }}
                     >
                         <div
                             style={{
                                 backgroundColor: this.props.lightMode ? "rgba(255,205,205,.42)" : "rgba(185,85,105,.42)",
                                 backdropFilter: "blur(7px)"
                             }}>
 
                             <CardMedia
                                 component="div"
                                 height="140"
                                 image={this.props.meditation.scene}
                                 alt={this.props.meditation.header}
                             >
                                 <Button
                                     sx={{ float: 'right', marginTop: "-20px", color: '#fff', background: "rgba(0,0,100,.15)" }}
                                     onClick={this.props.togglePlayerBar}
                                 >
                                     <MinimizeSharp fontSize='large' />
                                 </Button>
                                 <Typography
                                     className="meditationHeader"
                                     gutterBottom variant="h4" component="h4">
                                     {this.props.meditation.header}
                                 </Typography>
 
                             </CardMedia>
 
                             <CardContent>
                                 <Typography
                                     className="meditationTitle"
                                     gutterBottom variant="h5" component="div">
                                     {this.props.meditation.name}
                                 </Typography>
                                 <Typography
                                     className="authorName"
                                     style={{}}
                                     gutterBottom variant="body2" component="div">
                                     {this.props.meditation.description}
                                 </Typography>
 
                                 <div className="sliderBox">
 
                                     <Slider
                                         aria-label="time-indicator"
                                         size="small"
                                         value={played}
                                         min={0}
                                         step={0.01}
                                         max={0.99999}
                                         onChange={this.handleSeekChange}
                                         onChangeCommitted={this.handleSeekMouseUp}
                                         sx={{
                                             color: '#fff', //theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
                                             height: 4,
                                             '& .MuiSlider-thumb': {
                                                 width: 8,
                                                 height: 8,
                                                 transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                                 '&:before': {
                                                     boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                                                 },
                                                 '&:hover, &.Mui-focusVisible': {
                                                     boxShadow: `0px 0px 0px 8px rgb(255 255 255 / 16%)`
                                                 /* ${theme.palette.mode === 'dark'
                                                     ? 'rgb(255 255 255 / 16%)'
                                                     : 'rgb(0 0 0 / 16%)'
                                                     }*//*,
},
'&.Mui-active': {
width: 20,
height: 20,
},
},
'& .MuiSlider-rail': {
opacity: 0.28,
},
}}
/>
<Box
sx={{
display: 'flex',
alignItems: 'center',
justifyContent: 'space-between',
mt: -2,
}}
>
<div className="timePlayed">
{formatDuration(duration * played)}
</div>
<div className="timeRemaining">
{formatDuration(duration * (1 - played))}
</div>

</Box>
<Box
sx={{
display: 'flex',
alignItems: 'center',
justifyContent: 'center',
mt: -1,
}}
>
<IconButton aria-label="previous song"
onClick={this.props.prevArrow} >
<FastRewindRounded fontSize="large" htmlColor={this.state.mainIconColor} />
</IconButton>
<IconButton
aria-label={!playing ? 'play' : 'pause'}
onClick={this.handlePlayPause}
>

{!playing ? (
<PlayArrowRounded
sx={{ fontSize: '3rem' }}
htmlColor={this.state.mainIconColor}
/>
) : (
<PauseRounded sx={{ fontSize: '3rem' }} htmlColor={this.state.mainIconColor} />
)}
</IconButton>
<IconButton aria-label="next song"
onClick={this.props.nextArrow} >
<FastForwardRounded fontSize="large" htmlColor={this.state.mainIconColor} />
</IconButton>
</Box>
</div>
</CardContent>
<CardActions >
</CardActions>
</div>
</Card>
</>

);
}
*/

        const MaximalPlayerBar = () => {
            return (
                <>
                    <DrawerButton />

                    <AppBar
                        className={this.props.lightMode ? "playerBarMax" : "playerBarMaxDark"}
                        position="fixed"
                        sx={{
                            top: "auto",
                            bottom: 0,
                            backgroundColor: "rgba(255, 225, 255, .17)"
                        }}
                    >
                        <Box
                            className="meditationHeaderContainer"
                            sx={{ backgroundImage: `url(${this.props.meditation.scene})` }}
                        >

                            <Button
                                sx={{ color: '#fff', float: 'right', background: "rgba(0,0,100,.15)", padding: 1 }}
                                onClick={this.props.togglePlayerBar}
                            >
                                <div align='center' style={{
                                    background: "rgba(255,255,255,1)",
                                    borderRadius: "10px",
                                    height: "3px", width: "30px"
                                }}>

                                </div>
                            </Button>
                            <Typography
                                className="meditationHeader"
                                variant="h4" component="h4"
                            >
                                {this.props.meditation.header}
                            </Typography>
                            <Box
                                className="meditationHeaderInnerContainer"
                            >

                                <Typography
                                    className="meditationTitle"
                                    variant="h5" component="div"
                                >
                                    {this.props.meditation.name}
                                </Typography>
                                <Typography
                                    className="authorName"
                                    style={{ color: "white" }}
                                    variant="body2" component="div">
                                    by {this.props.meditation.artist}
                                </Typography>
                                <div className="sliderBoxMax">

                                    <Slider
                                        aria-label="time-indicator"
                                        size="small"
                                        value={played}
                                        min={0}
                                        step={0.01}
                                        max={0.99999}
                                        //onChange={this.handleSeekChange}
                                        sx={{
                                            color: '#fff', //theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
                                            height: 4,
                                            '& .MuiSlider-thumb': {
                                                width: 0,
                                                height: 0,
                                                transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                                '&:before': {
                                                    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                                                },
                                                '&:hover, &.Mui-focusVisible': {
                                                    boxShadow: `0px 0px 0px 8px rgb(255 255 255 / 16%)`
                                                /* ${theme.palette.mode === 'dark'
                                                    ? 'rgb(255 255 255 / 16%)'
                                                    : 'rgb(0 0 0 / 16%)'
                                                    }*/,
                                                },
                                                '&.Mui-active': {
                                                    width: 20,
                                                    height: 20,
                                                },
                                            },
                                            '& .MuiSlider-rail': {
                                                opacity: 0.28,
                                            },
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            mt: -2,
                                        }}
                                    >
                                        <div className="timePlayed">
                                            {formatDuration(duration * played)}
                                        </div>
                                        <div className="timeRemaining">
                                            {formatDuration(duration * (1 - played))}
                                        </div>

                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mb: 2,
                                        }}
                                    >
                                        <IconButton aria-label="previous song"
                                            onClick={this.handlePrev} >
                                            <FastRewindRounded fontSize="large" htmlColor={this.state.mainIconColor} />
                                        </IconButton>
                                        <IconButton
                                            aria-label={!playing ? 'play' : 'pause'}
                                            onClick={this.handlePlayPause}
                                        >

                                            {
                                                this.props.isReady ?

                                                    (
                                                        !playing ? (
                                                            <PlayArrowRounded
                                                                sx={{ fontSize: '3rem' }}
                                                                htmlColor={this.state.mainIconColor}
                                                            />
                                                        ) : (
                                                            <PauseRounded sx={{ fontSize: '3rem' }} htmlColor={this.state.mainIconColor} />
                                                        )
                                                    )
                                                    :
                                                    (
                                                        <CircularProgress />
                                                    )
                                            }
                                        </IconButton>
                                        <IconButton aria-label="next song"
                                            onClick={this.handleNext} >
                                            <FastForwardRounded fontSize="large" htmlColor={this.state.mainIconColor} />
                                        </IconButton>
                                    </Box>
                                </div>
                            </Box>
                        </Box>
                    </AppBar>
                </>

            );
        }

        const DrawerButton = () => {

            return (
                <div
                    style={{
                        width: '100%',
                        margin: 'auto',
                        padding: 'auto',
                        position: 'fixed',
                        top: 'auto',
                        bottom: this.props.isPlayerBar ? "115px" : "342px"
                    }}>
                    <Chip
                        onClick={this.props.togglePlayerBar}
                        label={
                            this.props.isPlayerBar
                                ?
                                <KeyboardArrowUp
                                    sx={{
                                        color: "green",
                                        opacity: 0.4
                                    }}
                                />
                                :
                                <KeyboardArrowDown
                                    sx={{
                                        color: "green",
                                        opacity: 0.4
                                    }}
                                />
                        }

                        sx={{
                            background: this.props.lightMode
                                ?
                                "rgba(255,255,255,.4)"
                                :
                                "rgba(0,0,0,.4)"
                        }}
                    />

                </div>
            );
        }

        const MinimalPlayerBar = () => {
            return (
                <>

                    <DrawerButton />
                    <AppBar
                        className={this.props.lightMode ? "playerBar" : "playerBarDark"}
                        position="fixed"
                        sx={{
                            top: "auto",
                            bottom: 0,
                            backgroundColor: "rgba(255, 225, 255, .17)",
                            background: `url(${this.props.meditation.scene})`,
                            backdropFilter: "blur(4px)"
                        }}
                    >

                        <Toolbar
                            className="meditationHeaderContainerBar"
                            sx={{
                                height: "inherit",
                                background: this.props.lightMode ?
                                    "rgba(255,255,255,.5)" : "rgba(0,0,0,.4)"
                            }}
                        >

                            <Box >
                                <IconButton
                                    onClick={this.handlePlayPause}
                                    className={this.props.lightMode ? "playerBarPlayButton" : "playerBarPlayButtonDark"}
                                    color="inherit" aria-label="open drawer"
                                >


                                    {
                                        this.props.isReady ?

                                            (
                                                !playing ? (
                                                    <PlayArrowRounded
                                                        className={this.props.lightMode ? "playerBarPlayButtonIcon" : "playerBarPlayButtonIconDark"}
                                                        sx={{ fontSize: '2rem' }}
                                                    />
                                                ) : (
                                                    <PauseRounded className={this.props.lightMode ? "playerBarPlayButtonIcon" : "playerBarPlayButtonIconDark"}
                                                        sx={{ fontSize: '2rem' }} />
                                                )
                                            )
                                            :
                                            (
                                                <CircularProgress />
                                            )
                                    }

                                </IconButton>

                                <IconButton
                                    disabled
                                >
                                    <Stack
                                        direction='column'
                                    >
                                        <Typography
                                            className={this.props.lightMode ? "meditationHeaderBar" : "meditationHeaderBarDark"}
                                            variant="h4" component="h3"

                                        >
                                            {this.props.meditation.header}
                                        </Typography>
                                        <Typography
                                            className={this.props.lightMode ? "meditationHeaderBar" : "meditationHeaderBarDark"}
                                            variant="body1" component="h3"
                                            sx={{ display: { xs: 'true', sm: 'none' } }}
                                        >
                                            by {this.props.meditation.artist}
                                        </Typography>

                                    </Stack>


                                </IconButton>
                            </Box>

                            <Box sx={{ flexGrow: 1 }} />
                            <IconButton
                                sx={{
                                    display: { xs: 'none', md: 'flex' }
                                }}
                                onClick={this.handlePrev}
                            >

                                <SkipPrevious
                                    sx={{
                                        color:
                                            this.props.lightMode
                                                ? "inherit" : "rgba(255,255,255,.4)"
                                    }}
                                />

                            </IconButton>
                            <IconButton
                                disabled
                                sx={{
                                    display: { xs: 'none', sm: 'flex' }
                                }}
                            >
                                <Typography
                                    className={this.props.lightMode ? "meditationTitleBar" : "meditationTitleBarDark"}
                                    variant="h5" component="div">
                                    {this.props.meditation.name}
                                    <Divider sx={{ background: "rgba(255,255,255,0.2)" }} />
                                    <Typography
                                        className={this.props.lightMode ? "meditationArtistBar" : "meditationArtistBarDark"}
                                        variant="h6"
                                        component="div">
                                        by {this.props.meditation.artist}
                                    </Typography>

                                </Typography>
                            </IconButton>
                            <IconButton
                                sx={{
                                    display: { xs: 'none', md: 'flex' }
                                }}
                                onClick={this.handleNext}
                            >

                                <SkipNext sx={{ color: this.props.lightMode ? "inherit" : "rgba(255,255,255,.4)" }} />

                            </IconButton>
                            <Box sx={{ flexGrow: 1 }} />
                            <IconButton

                                sx={{ float: 'right' }}
                                onClick={this.props.togglePlayerBar}
                            >
                                <Typography
                                    className={this.props.lightMode ? "meditationTimePlayedBar" : "meditationTimePlayedBarDark"}
                                    variant="h4" component="div">
                                    {formatDuration(duration * played)}
                                    <Divider sx={{ background: "rgba(255,255,255,0.4)" }} />
                                    <Typography
                                        className={this.props.lightMode ? "meditationTimeRemainingBar" : "meditationTimeRemainingBarDark"}
                                        variant="h5"
                                        component="div">
                                        {formatDuration(duration * (1 - played))}
                                    </Typography>

                                </Typography>
                            </IconButton>

                        </Toolbar>
                    </AppBar>
                </>

            );
        }
        if (screenfull.isEnabled) {
            screenfull.on('change', () => {
                console.log('screenchange');
                if (screenfull.isFullscreen) {
                    if (!this.state.isFullscreen) {
                        this.setState({ isFullscreen: true });
                        console.log('I Am fullscreen', screenfull.isFullscreen ? 'Yes' : 'No');
                        console.log('State?', this.state.isFullscreen ? 'Yes' : 'No');
                    }

                } else if (!screenfull.isFullscreen) {
                    if (this.state.isFullscreen) {
                        this.setState({ isFullscreen: false });
                        console.log('I Am Not fullscreen', screenfull.isFullscreen ? 'Yes' : 'No');
                        console.log('State?', this.state.isFullscreen ? 'Yes' : 'No');
                    }


                }
            });
        }

        return (
            <>
                <div ref={this.ref2}

                    className='playerApp'

                >
                    <div className='player-wrapper'>
                        <ReactPlayer
                            ref={this.ref}
                            className='react-player'
                            width='100%'
                            height='100%'
                            url={this.props.meditation.file}
                            pip={pip}
                            playing={playing}
                            controls={false}
                            light={light}
                            loop={loop}
                            playbackRate={playbackRate}
                            volume={volume}
                            muted={muted}
                            onReady={() => {
                                // this.props.setIsReady(true);
                                console.log('onReady');
                                this.props.setIsReady(true);
                            }}
                            onStart={() => {
                                this.handlePlay();
                                this.props.setIsReady(true);
                                console.log('onStart');
                            }}
                            onPlay={this.handlePlay}
                            onEnablePIP={this.handleEnablePIP}
                            onDisablePIP={this.handleDisablePIP}
                            onPause={() => {
                                console.log("PAUSE");
                                this.handleError();
                            }}
                            onBuffer={() => {
                                console.log("BUFFER");
                                this.props.isBuffer();
                                this.handlePlay();
                            }}
                            onBufferEnd={() => {
                                console.log("BUFFER END");
                                this.props.setIsReady(true);
                                this.handlePlay();
                            }}
                            onPlaybackRateChange={this.handleOnPlaybackRateChange}
                            onSeek={e => console.log('onSeek', e)}
                            onEnded={this.handleEnded}
                            onError={e => console.log('onError', e)}
                            onProgress={this.handleProgress}
                            onDuration={this.handleDuration}
                        />
                    </div>

                    {
                        this.props.isPlayerBar
                            ?
                            <MinimalPlayerBar />
                            :
                            <MaximalPlayerBar />



                    }

                </div >
            </>
        )
    }
}

export default hot(module)(App)