import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import QRView from './QRView';
import ManagerView from './CreatorView';
import reportWebVitals from './reportWebVitals';
import {
  HashRouter,
  Routes,
  Route
} from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
const theme = createTheme({
  status: {
    danger: orange[500],
  },
});

ReactDOM.render(
  <React.StrictMode>

    <ThemeProvider theme={theme}>
      <HashRouter>
        <Routes>
          <Route
            path="super/create/:partner"
            element={
              <ManagerView superAdmin={true} />}
          />
          <Route
            path="create/:partner"
            element={
              <ManagerView superAdmin={false} />}
          />
          <Route
            path=":partner"
            element={
              <QRView />}
          />
          <Route
            path=""
            element={
              <QRView />}
          />

        </Routes>
      </HashRouter>

    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
