import { useState } from 'react';

//
import { findDOMNode } from 'react-dom';



//
import './App.css';
import './css/waveform.css';
import screenfull from 'screenfull';
import { Button, Fab, Box, SpeedDial, SpeedDialIcon, Tooltip, SpeedDialAction } from '@mui/material';
import MeditationApp from './MeditationApp';

//
import config from './assets/config.json';
import NavBar from './Components/NavBar';
import { CloseFullscreenRounded, OpenInFullRounded } from '@mui/icons-material';
import LightSwitch from './Components/LightSwitch';
import Welcome from './Welcome';


const App = (props) => {

  const [hasEntered, setHadEntered] = useState(false);

  const [currentSelection, setSelection] = useState(0);
  const [lightMode, setLightMode] = useState(true);
  const [isPlayerBar, setIsPlayerBar] = useState(false);
  const [fullscreenComponent, setFullscreenComponent] = useState(undefined);

  const [isFullscreen, setFullscreen] = useState(false);

  const partner = props.partner ?
    config.partners[props.partner] ? config.partners[props.partner] : config.partners["default"]
    :
    config.partners["default"]
    ;
  const partnerStyle = partner.styles[lightMode ? "light" : "dark"];
  if (screenfull.isEnabled) {
    screenfull.on('change', () => {
      console.log('screenchange');
      if (screenfull.isFullscreen) {
        if (!isFullscreen) {
          setFullscreen(true);
          console.log('I Am fullscreen', screenfull.isFullscreen ? 'Yes' : 'No');
          console.log('State?', isFullscreen ? 'Yes' : 'No');
        }

      } else if (!screenfull.isFullscreen) {
        if (isFullscreen) {
          setFullscreen(false);
          console.log('I Am Not fullscreen', screenfull.isFullscreen ? 'Yes' : 'No');
          console.log('State?', isFullscreen ? 'Yes' : 'No');
        }


      }
    });
  }
  const handleClickFullscreen = () => {
    if (!isFullscreen) {
      screenfull.request(findDOMNode(fullscreenComponent));
    } else {
      screenfull.toggle();
    }

  }
  const togglePlayerBar = () => {
    const t = isPlayerBar;
    setIsPlayerBar(!t);
  }

  const LightModeSwitch = () => {
    return (
      <>
        <Fab variant="extended"
          style={{
            position: 'fixed',
            top: "15px",
            right: "15px",
            background: lightMode ? 'rgba(255,255,255,.1)' : 'rgba(0,0,0,.1)'
          }}>
          <Button
          >

            <LightSwitch
              partnerStyle={partnerStyle}
              lightMode={lightMode}
              setLightMode={setLightMode}
            />

          </Button>
        </Fab>
      </>
    );
  }

  const FullscreenButton = () => {
    return (
      <>
        <Fab variant="extended"
          style={{
            position: 'fixed',
            bottom: hasEntered ? (isPlayerBar ? "125px" : "355px") : "15px",
            right: "15px",
            background: lightMode ? 'rgba(255,255,255,.1)' : 'rgba(0,0,0,.1)'
          }}>
          <Button
            onClick={
              handleClickFullscreen
            }
          >
            {
              isFullscreen
                ?
                <CloseFullscreenRounded />
                :
                <OpenInFullRounded />
            }

          </Button>
        </Fab>
      </>
    );
  }


  const LogoBackdrop = () => {

    return (
      <>
        <img src={
          lightMode
            ?
            (!partner.isPremium ? config.providers[partner.provider].iconLarge : partner.logo)
            :
            (
              !partner.isPremium
                ?
                config.providers[partner.provider].iconLargeDark
                :
                partner.logoDark
            )
        }
          style={{
            position: 'fixed',
            maxWidth: '400px',
            top: "105px",
            left: "75px",
            opacity: 0.1
          }}
        />
      </>
    );
  }

  return (
    <div className={lightMode ? "App-light" : "App-dark"}
      style={{
        overflowX: 'clip',
        background: partnerStyle.background
      }}
    >
      <NavBar
        partner={partner}
        partnerStyle={partnerStyle}
        hasEntered={hasEntered}
        currentSelection={currentSelection}
        setSelection={setSelection}
        lightMode={lightMode}
        setLightMode={setLightMode}
        home={() => { setHadEntered(false) }}
      />
      <div
        ref={setFullscreenComponent}
        className={
          lightMode
            ?
            "meditationApp-light"
            :
            "meditationApp-dark"
        }
        style={{
          background: partnerStyle.background
        }}
      >
        {
          isFullscreen
          &&
          <>
            <LightModeSwitch />
            <LogoBackdrop />
          </>

        }

        {hasEntered
          ?

          <MeditationApp
            partnerStyle={partnerStyle}
            lightMode={lightMode}
            togglePlayerBar={togglePlayerBar}
            isPlayerBar={isPlayerBar}
            partner={props.partner ? props.partner : "default"}
            currentSelection={currentSelection}
            setSelection={setSelection}
          />
          :
          <Welcome
            partnerStyle={partnerStyle}
            enter={() => setHadEntered(true)}
            lightMode={lightMode}
            partner={props.partner ? props.partner : "default"}
          />
        }
        {hasEntered && <FullscreenButton />}
      </div>
    </div >
  );
}

export default App;
