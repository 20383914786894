import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Grid } from '@mui/material';

export default function MeditationCard(props) {
    const theme = useTheme();
    const index = props.index;
    const meditation = props.meditation;
    const isSelected = props.isSelected;

    const selectedOptions =
        isSelected ?
            {
                border: '5px solid orange'
            }
            :
            undefined;
    return (
        <Grid item xs={12} sm={4} md={3} >
            <Card
                onClick={props.select}
                sx={{
                    display: 'flex', maxWidth: "200px", margin: 'auto', my: 2,
                    background: `url(${meditation.scene})`,
                    border: props.border
                }}
            >
                <Box sx={{
                    display: 'flex', flexDirection: 'column',
                    width: '100%',
                    background: "rgba(255,255,255,.4)"
                }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h5">
                            {meditation.header}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            {meditation.name}
                        </Typography>

                        <Typography variant="subtitle2" color="text.secondary" component="div">
                            {meditation.artist}
                        </Typography>
                    </CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', margin: 'auto', pb: 1 }}>
                        <IconButton aria-label="previous">
                            {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
                        </IconButton>
                        <IconButton aria-label="play/pause">
                            <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                        </IconButton>
                        <IconButton aria-label="next">
                            {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
                        </IconButton>
                    </Box>
                </Box >
            </Card >
        </Grid >
    );
}
