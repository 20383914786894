import ReactPlayer from 'react-player';
import React, { useEffect, useState } from 'react';
import Player from './Player';

//
import { findDOMNode } from 'react-dom';
import { hot } from 'react-hot-loader';



//
import './App.css';
import './css/waveform.css';
import screenfull from 'screenfull';
import { Button, Container, Fab, Grid } from '@mui/material';

//
import config from './assets/config.json';
import BreathBox from './Components/BreathBox';
import NavBar from './Components/NavBar';
import { CloseFullscreenRounded, OpenInFull, OpenInFullRounded } from '@mui/icons-material';
import LightSwitch from './Components/LightSwitch';
import { Box } from '@mui/system';


function MeditationApp(props) {


  const isPlayerBar = props.isPlayerBar;
  const setIsPlayerBar = props.setIsPlayerBar;
  const togglePlayerBar = props.togglePlayerBar;
  const [isReady, setIsReady] = useState(false);
  const [play, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.4);
  const [progress, setProgress] = useState(0);
  const [seeking, setSeeking] = useState(0);
  const [viewa, setViewa] = useState(undefined);
  const [isFullscreen, setFullscreen] = useState(false);

  const lightMode = props.lightMode;
  const currentSelection = props.currentSelection;
  const setSelection = props.setSelection;
  const partnerStyle = props.partnerStyle;
  if (screenfull.isEnabled) {
    screenfull.on('change', () => {
      console.log('screenchange');
      if (screenfull.isFullscreen) {
        if (!isFullscreen) {
          setFullscreen(true);
          console.log('I Am fullscreen', screenfull.isFullscreen ? 'Yes' : 'No');
          console.log('State?', isFullscreen ? 'Yes' : 'No');
        }

      } else if (!screenfull.isFullscreen) {
        if (isFullscreen) {
          setFullscreen(false);
          console.log('I Am Not fullscreen', screenfull.isFullscreen ? 'Yes' : 'No');
          console.log('State?', isFullscreen ? 'Yes' : 'No');
        }


      }
    });
  }
  const handleClickFullscreen = () => {
    if (!isFullscreen) {
      screenfull.request(findDOMNode(viewa));
    } else {
      screenfull.toggle();
    }

  }

  const partner = props.partner ?
    config.partners[props.partner] ? config.partners[props.partner] : config.partners["default"]
    :
    config.partners["default"]
    ;
  const provider = config.providers[partner.provider];

  const t = partner.meditations;


  const meditations = t.map((d, i) => {
    return config.meditations[parseInt(d)];
  });

  console.log(partner);



  return (
    <React.Fragment>


      <Container

        sx={{
          justifyContent: 'center',
          paddingTop: isFullscreen
            ?
            '20vh'
            :
            '10vh'
        }}>
        <BreathBox

          image={
            partnerStyle.breatheBox
              ? partnerStyle.breatheBox : undefined
          }
          playing={play}
          isFullScreen={isFullscreen}
          isPlayerBar={isPlayerBar}
          lightMode={lightMode}
        />
      </Container>

      <Player
        partnerStyle={partnerStyle}
        handleClickFullscreen={handleClickFullscreen}
        isPlayerBar={isPlayerBar}
        isReady={isReady}
        setIsReady={() => setIsReady(true)}
        isBuffer={() => setIsReady(false)}
        togglePlayerBar={togglePlayerBar}
        lightMode={lightMode}
        title={"Meditation"}
        meditation={meditations[currentSelection]}
        isFullscreen={isFullscreen}
        setPlay={setPlay}
        play={play}
        prevArrow={() => {
          if (currentSelection > 0) {
            const t = currentSelection;
            setSelection(t - 1);
          } else {
            setSelection(meditations.length - 1);
          }
        }}
        nextArrow={() => {
          if (currentSelection < meditations.length - 1) {
            const t = currentSelection;
            setSelection(t + 1);
          } else {
            setSelection(0);
          }
        }}
      />
    </React.Fragment >
  );
}

export default MeditationApp;
