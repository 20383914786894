

import { Box } from '@mui/system';
import logo from '../assets/breath/box.svg';
import '../css/breathbox.css';

function BreathBox(props) {

  const breathBox = props.image ? props.image : logo;
  const NormalBreathBox = () => {

    if (props.isPlayerBar) {
      return (

        <img src={breathBox}
          className={props.playing ? `breathBox breathBoxAnimated${props.lightMode ? " breathBoxDark" : ""}` : `breathBox${props.lightMode ? " breathBoxDark" : ""}`}
          alt="logo" style={{ maxWidth: "300px", margin: 'auto' }} />

      );
    } else {
      return (
        <img src={breathBox}
          className={props.playing ? `breathBox breathBoxAnimated${props.lightMode ? " breathBoxDark" : ""}` : `breathBox${props.lightMode ? " breathBoxDark" : ""}`}
          alt="logo" style={{ maxWidth: "300px", margin: 'auto', marginBottom: "5vh" }} />

      );
    }

  }


  return <NormalBreathBox className={props.lightMode ? "beathBox" : "beathBoxDark"} />;


}

export default BreathBox;
