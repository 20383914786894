'use strict'

import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

class SketchExample extends React.Component {

    state = {
        displayColorPicker: false,
        color: { r: "", g: "", b: "", a: "" }
    };

    componentDidMount = () => {
        this.setState({ color: this.decodeRGBString(this.props.color) });
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.setState({ color: color.rgb });
    };

    handleChangeCommited = (color) => {
        this.props.handleChange(this.encodeRGBString(color.rgb));
        this.setState({ color: color.rgb, displayColorPicker: true });
    };

    encodeRGBString = (rgb) => {
        return `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
    }

    decodeRGBString = (input: string) => {
        const parse = input.slice(5, input.length - 1).split(',');
        const output = {
            r: parse[0],
            g: parse[1],
            b: parse[2],
            a: parse[3]
        }
        console.log(output);
        return output;
    }

    render() {

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'fixed',
                    bottom: '180px',//'-275px',
                    right: '375px',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div>
                <div style={styles.swatch} onClick={this.handleClick}>
                    <div style={styles.color} />
                </div>
                {this.state.displayColorPicker ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleClose} />
                    <SketchPicker
                        color={this.state.color}
                        onChange={this.handleChange}
                        onChangeComplete={this.handleChangeCommited}
                    />
                </div> : null}

            </div>
        )
    }
}

export default SketchExample