import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

//
import config from '../assets/config.json';
import { styled } from '@mui/material/styles';
import { FormControlLabel, Stack } from '@mui/material';
import LightSwitch from './LightSwitch';
import { ArrowDropUp, ArrowUpwardSharp, CloseOutlined, MarginOutlined } from '@mui/icons-material';



const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = (props) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    console.log("a", props.partner);
    const partner = props.partner ? props.partner : config.partners["default"];
    const provider = config.providers[partner.provider];
    console.log(partner, props.partner);
    const pages = partner.meditations.map((d, i) => {
        return config.meditations[parseInt(d)].header;
    });
    const meditations = partner.meditations.map((d, i) => {
        return config.meditations[parseInt(d)];
    });
    const partnerStyle = props.partnerStyle;

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const Logo = () => {
        console.log(partner.name);
        const logo =
            partner.isPremium ?
                (props.lightMode ? partner.logo : partner.logoDark)
                :
                (props.lightMode ? provider.logo : provider.logoDark)
            ;
        return (
            <img
                onClick={props.home}
                src={logo} style={{ maxHeight: "100px", maxWidth: "200px", margin: 'auto', padding: "20px" }} />
        );

    }

    return (
        <AppBar
            position="static"
            style={{
                background: props.lightMode ? "rgba(255,255,255,0.9)" : "rgba(0,10,30,.7)"
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    {
                        props.hasEntered
                            ?
                            <>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{
                                        display: { xs: 'none', md: 'flex' }
                                    }}
                                >
                                    <Logo />
                                </Typography>
                                <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <MenuIcon sx={{ color: partnerStyle.contrast }} />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: { xs: 'block', md: 'none' },
                                        }}
                                    >
                                        <MenuItem disabled >
                                            <Typography textAlign="center"
                                                sx={{ color: "rgba(255,0,0,1)" }}
                                            >
                                                Meditations</Typography>
                                        </MenuItem>

                                        {pages.map((page, i) => (
                                            <Tooltip disableFocusListener title={`${meditations[i].name} by ${meditations[i].artist}`}>

                                                <MenuItem key={page} onClick={() => {
                                                    props.setSelection(i);
                                                    handleCloseNavMenu();
                                                }}>
                                                    <Typography textAlign="center">{page}</Typography>
                                                </MenuItem>
                                            </Tooltip>
                                        ))}
                                    </Menu>
                                </Box>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, margin: "auto" }}
                                >
                                    <Logo />
                                </Typography>
                                <Box sx={{
                                    flexGrow: 1, display: { xs: 'none', md: 'flex' },
                                    float: 'right', margin: 'auto'
                                }}>
                                    {pages.map(
                                        (page, i) => (
                                            <>
                                                <Stack>
                                                    <Button
                                                        key={page}
                                                        onClick={() => props.setSelection(i)}
                                                        sx={{
                                                            mt: 4,
                                                            mb: 0,
                                                            mx:
                                                                props.currentSelection == i
                                                                    ?
                                                                    3
                                                                    :
                                                                    0,
                                                            display: 'block',
                                                            fontSize: '14px + 2vw',
                                                            transform: props.currentSelection == i
                                                                ?
                                                                "scale(1.2)"
                                                                :
                                                                "scale(1)",
                                                            letterSpacing: props.lightMode ? 2 : 3,
                                                            color:
                                                                props.currentSelection == i
                                                                    ?
                                                                    (partnerStyle.primary)
                                                                    :
                                                                    (props.lightMode ? "rgba(0,0,0,1)" : "rgba(255,255,255,1)"),
                                                            textTransform: props.lightMode ? 'uppercase' : 'lowercase'

                                                        }}
                                                    >
                                                        {page}
                                                    </Button>

                                                    <ArrowDropUp sx={{
                                                        mx: 'auto',
                                                        my: 0,
                                                        opacity: props.currentSelection == i
                                                            ?
                                                            1
                                                            :
                                                            0,
                                                        color: partnerStyle.primary
                                                    }}
                                                    />
                                                </Stack>
                                            </>
                                        )
                                    )}
                                </Box>
                            </>
                            :
                            <>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={0}
                                >
                                    <img
                                        onClick={props.home}
                                        src={partner.isPremium ?
                                            (props.lightMode ? partner.logo : partner.logoDark)
                                            :
                                            (props.lightMode ? provider.logo : provider.logoDark)}

                                        style={{
                                            maxHeight: "100px", maxWidth: "150px", margin: 'auto', padding: "5px"
                                        }} />
                                    {
                                        partner.name != "buddhabooth"
                                        &&
                                        <>
                                            <CloseOutlined fontSize='large'
                                                sx={{ color: props.lightMode ? "rgba(0,0,0,.7)" : "inherit" }}
                                            />
                                            <img
                                                src={(props.lightMode ? provider.icon : provider.iconDark)}
                                                style={{
                                                    maxHeight: "80px", maxWidth: "200px",
                                                    margin: 'auto',
                                                    marginLeft: '5px'
                                                }}
                                            />
                                        </>
                                    }

                                </Stack>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        display: { xs: 'flex', md: 'flex' }
                                    }}
                                >

                                </Box>

                            </>

                    }




                    <Box sx={{ margin: 'auto', alignContent: 'center' }}>
                        <FormControlLabel
                            control={
                                <LightSwitch
                                    partnerStyle={props.partnerStyle}
                                    lightMode={props.lightMode}
                                    setLightMode={props.setLightMode}
                                />}
                            label={""}
                            sx={{ margin: 'auto' }}

                        />
                    </Box>

                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default ResponsiveAppBar;