
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { Box, height } from "@mui/system";

import config from '../assets/config.json';
import '../App.css';

export default function WelcomePanel(props) {

    const partner = props.partner;
    const partnerStyle = props.partnerStyle ? props.partnerStyle : config.templates.style;

    return (
        <>
            <Paper
                sx={{
                    background: `url(${partner.scene ? partner.scene : config.meditations[0].scene})`,
                    backgroundSize: 'cover',
                    minHeight: "400px",
                    mt: 2,
                    paddingTop: 10,
                    minWidth: '100%'
                }}>
                <Grid container spacing={2} sx={{ margin: 'auto' }}>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={12} sm={8}
                        sx={{
                            background: "linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.2))",
                            borderRadius: "20px",
                            border: "2px inset rgba(0,0,0,.1)",
                            backdropFilter: 'blur(2px)'
                        }}
                    >
                        {
                            partner.tagline
                            &&
                            <Typography variant="h4" sx={{ fontWeight: 600, my: 2, mx: 1 }} >

                                <span
                                    style={{
                                        color:
                                            partnerStyle.h1
                                    }}
                                >
                                    {partner.tagline && partner.tagline}
                                </span>
                            </Typography>
                        }
                        {
                            partner.subtitle
                            &&
                            <Typography variant="h6" sx={{ fontWeight: 600, my: 2, mx: 1 }} >

                                <span
                                    style={{
                                        color:
                                            partnerStyle.h2
                                    }}
                                >
                                    {partner.subtitle && partner.subtitle}
                                </span>
                            </Typography>
                        }
                        <Typography variant="body1" sx={{
                            fontWeight: 400, margin: 'auto', my: 2, width: '77%'
                        }} >

                            <span style={{
                                color: partnerStyle.body
                            }} >
                                Enter the meditation portal, where

                            </span>{" "}
                            <span style={{
                                color: partnerStyle.body,
                                fontWeight: 700,
                            }} >
                                you'll find a selection of guided meditions and breathing exercises.
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={false} sm={4}>

                    </Grid>


                    <Grid item xs={12} sm={8}>

                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box sx={{ margin: 3 }}>

                            <Button
                                sx={{
                                    border: "2px inset rgba(0,0,0,.2)",
                                    backdropFilter: 'blur(4px)',
                                    background:
                                        props.lightMode
                                            ?
                                            'radial-gradient(rgba(200,255,255,0.2) 10%, rgba(80,100,100,.3) 80%, rgba(0,0,0,.3) 100%)'
                                            :
                                            'radial-gradient(rgba(0,0,0,0.4) 10%, rgba(80,100,100,.3) 80%, rgba(0,0,0,.3) 100%)',
                                    textTransform: 'initial',
                                    margin: 'auto', borderRadius: "100px"
                                }}
                                onClick={props.enter}
                            >
                                <Typography variant="h6" sx={{ margin: 2 }} >

                                    <span style={{
                                        fontWeight: 700,
                                        color: partnerStyle.h2
                                    }} >
                                        Begin your Journey
                                    </span>
                                </Typography>

                            </Button>

                        </Box>
                    </Grid>

                </Grid>
            </Paper></>
    );
}