import App from "./App";
import { orange } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useState } from "react";

import config from './assets/config.json';
import './App.css';

import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { Box, height } from "@mui/system";
import FooterBar from "./Components/Footer";
import { Instagram, Language, Twitter } from "@mui/icons-material";
import WelcomePanel from "./Components/WelcomePanel";

function Welcome(props) {

    const previewMode = props.previewMode;

    const partner = previewMode ?
        props.partner
        :
        config.partners[props.partner]
            ?
            config.partners[props.partner]
            :
            config.partners["default"]
        ;

    const subPartner = partner.subPartner ? config.subPartners[partner.subPartner] : undefined;
    const partnerStyle = partner.styles[props.lightMode ? 'light' : 'dark'];
    const PoweredByTriple = () => {
        return (
            <>

                <Grid container item xs={12} spacing={2} sx={{
                    justifyContent: 'center', margin: 'auto', marginTop: 5
                }}>

                    <Stack direction={'row'}
                    >

                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, margin: "auto" }}
                        >

                            {
                                config.providers &&
                                <img
                                    src={
                                        props.lightMode
                                            ?
                                            config.providers[partner.provider].icon
                                            :
                                            config.providers[partner.provider].iconDark
                                    }
                                    style={{ maxWidth: '150px', margin: 'auto' }} />
                            }
                        </Typography>
                        <Typography
                            align='center'
                            variant="h2"
                            component="div"
                            sx={{
                                height: '60px',
                                width: '60px',
                                borderRadius: '100px',
                                zIndex: 2,
                                color: partnerStyle.accent,
                                margin: "auto",
                                mx: '-25px',
                                paddingBottom: 2
                            }}
                        >
                            @
                        </Typography>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, margin: "auto" }}
                        >

                            <img src={props.lightMode ? subPartner.logo : subPartner.logoDark} style={{ maxWidth: '250px', margin: 'auto' }} />
                        </Typography>
                    </Stack>

                </Grid>
            </>
        );
    }
    const PoweredBy = () => {
        return (
            <Typography variant="h6" sx={{ margin: 2, mb: 8 }} >

                {
                    partner.isPremium
                        ?
                        <>
                            <span style={{ color: partnerStyle.accent }} >
                                brought to you by
                            </span>
                            {" "}
                            <span style={{ color: partnerStyle.contrast }} >
                                {partner.name}
                            </span>
                        </>
                        :
                        <>
                            <span style={{
                                color: partnerStyle.contrast,
                                fontSize: "15px"
                            }} >
                                powered by {" "}
                                <span
                                    style={{
                                        verticalAlign: 'baseline',
                                        fontWeight: 700
                                    }}
                                >
                                    <img
                                        src={config.providers[partner.provider].logo}
                                        style={{
                                            verticalAlign: 'bottom',
                                            maxWidth: "200px"
                                        }}
                                    />
                                </span>
                            </span>
                        </>
                }


            </Typography>
        );
    }
    const socialLinks = [

        {
            name: "website",
            icon: Language,
            link: partner.website ? partner.website : undefined,
            isPresent: partner.website ? true : false
        },
        {
            name: "twitter",
            icon: Twitter,
            link: partner.twitter ? `https://www.twitter.com/${partner.twitter}` : undefined,
            isPresent: partner.twitter ? true : false
        },
        {
            name: "instagram",
            icon: Instagram,
            link: partner.instagram ? `https://www.instagram.com/${partner.instagram}` : undefined,
            isPresent: partner.instagram ? true : false
        }

    ]

    return (
        <div style={{
            width: '100%'
        }}>

            <Paper
                elevation={6}
                sx={{
                    marginTop: 7,
                    padding: 2,
                    background: partnerStyle.primary,

                }}
            >
                <Typography variant="h2" >

                    <span style={{ color: partnerStyle.accent }} >
                        Welcome
                    </span>
                    {" "}
                    <span style={{ color: partnerStyle.contrast }} >
                        Inside
                    </span>
                </Typography>

            </Paper>
            {subPartner && <PoweredByTriple />}
            <PoweredBy />
            <WelcomePanel
                partnerStyle={partnerStyle}
                partner={partner}
                enter={props.enter}
                lightMode={props.lightMode}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ margin: 3 }}>
                </Grid>

                <Grid item xs={12} sm={12} sx={{ margin: 'auto' }}>
                    <Stack
                        direction='row'
                        sx={{
                            margin: 'auto',
                            maxWidth: "200px",
                            display: { xs: 'flex', md: 'none' }
                        }}
                    >
                        {socialLinks.map((social) => {
                            if (social.isPresent) {
                                return (
                                    <Button
                                        key={social.name}
                                        onClick={() => window.open(social.link)
                                        }
                                        sx={{
                                            margin: 'auto',
                                            color: partnerStyle.contrast,
                                            display: 'block'
                                        }}
                                    >
                                        <social.icon />
                                    </Button>
                                );
                            }

                        })}
                    </Stack>
                    <Typography>
                        {
                            partner.name == "buddhabooth"
                                ?
                                <img src={props.lightMode ? partner.logo : partner.logoDark}
                                    style={{
                                        maxWidth: "200px"
                                    }} />
                                :
                                <Typography variant="h5"
                                    sx={{
                                        color: partnerStyle.accent
                                    }}
                                >
                                    {partner.name}
                                </Typography>


                        }
                    </Typography>

                    {partner.email
                        &&
                        <Typography>
                            <Button onClick={() => window.open(`mailto:${partner.email}`)}
                                sx={{
                                    textTransform: 'initial',
                                    color: partnerStyle.accent
                                }}
                            >

                                {partner.email}

                            </Button>
                        </Typography>
                    }
                    {
                        partner.website
                        &&
                        <Typography>
                            <Button onClick={() => window.open(partner.website)}
                                sx={{
                                    textTransform: 'initial',
                                    color: partnerStyle.accent
                                }}
                            >

                                {partner.website + partner.specialLink}

                            </Button>
                        </Typography>
                    }

                </Grid>
                <Grid item xs={12}>
                    <div style={{
                        marginTop: 10,
                        height: '100px'
                    }}>
                        {" "}
                    </div>
                </Grid>

            </Grid>


            <FooterBar
                lightMode={props.lightMode}
                partner={partner ? partner : config.partners["default"]}
            />
        </div>
    );
}

export default Welcome;

