
import App from "./App";
import { orange } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useState, useRef } from "react";
import { toPng } from "html-to-image";
import config from './assets/config.json';
import './App.css';
import { Backdrop, Button, ButtonGroup, Grid, Paper, Snackbar, Stack, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import FooterBar from "./Components/Footer";
import { Box, height, width } from "@mui/system";
import { Instagram, Language, Twitter } from "@mui/icons-material";

import { useParams } from "react-router-dom";
import QRCode from "react-qr-code"; // "qrcode.react";
import MeditationCard from "./Components/MeditationCard";
import { hot } from "react-hot-loader";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import WelcomePanel from "./Components/WelcomePanel";
import Welcome from "./Welcome";
import LightSwitch from "./Components/LightSwitch";
import { ChromePicker, SketchPicker } from "react-color";
import ColorChoice from "./Components/ColorChoice";

function CreatorView(props) {
    const params = useParams();
    const partner = config.partners[params.partner]
        ?
        config.partners[params.partner]
        :
        config.partners["default"];

    const [largeQR, setLargeQR] = useState(false);
    const [provider, setProvider] = useState(1);
    let providerLogos = [];
    config.providers.map(
        (d, i) => {
            providerLogos.push(d.logo);
            return true;
        }
    );


    const [downloadingImage, setdownloadingImage] = useState(false);
    const [notes, setNotes]
        = useState(undefined);

    const [colorDesignMode, setColorDesignMode] = useState(props.superAdmin ? true : false);
    const [miniPreview, setMiniPreview] = useState(false);
    const [previewLightmode, setpreviewLightmode] = useState(true);
    const [subProvider, setSubProvider] = useState(undefined);
    const [partnerStyle, setPartnerStyle] = useState(config.templates.style);
    const [subProviderChoice, setSubProviderChoice] = useState(false);
    const [name, setname] = useState(undefined);
    const [tagline, settagline] = useState(undefined);
    const [website, setwebsite] = useState(undefined);
    const [subtitle, setsubtitle] = useState(undefined);
    const [specialLink, setspecialLink] = useState(undefined);
    const [email, setemail] = useState(undefined);
    const [logo, setlogo] = useState(undefined);
    const [logoDark, setlogoDark] = useState(undefined);
    const [isPremium, setisPremium] = useState(true);
    const [instagram, setinstagram] = useState(undefined);
    const [twitter, settwitter] = useState(undefined);
    const [scene, setscene] = useState("https://cdn.pixabay.com/photo/2015/03/11/12/31/buildings-668616_960_720.jpg");
    // Meditation Selector
    const [S0, SS0] = useState(false);
    const [S1, SS1] = useState(false);
    const [S2, SS2] = useState(false);
    const [S3, SS3] = useState(false);
    const [S4, SS4] = useState(false);
    const [S5, SS5] = useState(false);
    const [S6, SS6] = useState(false);
    const [S7, SS7] = useState(false);

    const getters = [S0, S1, S2, S3, S4, S5, S6, S7];
    const setters = [SS0, SS1, SS2, SS3, SS4, SS5, SS6, SS7];
    //

    const getMeditationChoice = () => {
        let meditationSelection = [];
        getters.map(
            (d, i) => {
                if (d) {
                    console.log("Selected Meditation", i);
                    meditationSelection.push(i);
                }
                return true;
            }
        );
        return meditationSelection;
    }
    const previewPartner =
    {
        provider: provider ? provider : "0",
        name: name ? name : "Partner Name",
        tagline: tagline ? tagline : "This is the tagline",
        subtitle: subtitle ? subtitle : "this is the subtitle.",
        specialLink: specialLink ? specialLink : "/",
        website: website ? website : "www.website.com",
        email: email ? email : "email@gmail.com",
        logo: logo ? logo : config.providers[1].logo,
        logoDark: logoDark ? logoDark : config.providers[1].logoDark,
        isPremium: isPremium ? isPremium : true,
        instagram: instagram ? instagram : "",
        twitter: twitter ? twitter : "",
        scene: scene ? scene : config.partners[0].scene,
        meditations: getMeditationChoice(),
        subPartner: subProvider ? subProvider : false,
        styles: partnerStyle
    }

    const ColorPicker = (startColor, handleColorChange) => {
        return (
            <ChromePicker
                color={startColor}
                onChange={handleColorChange}
                onChangeComplete={handleColorChange}
            />
        );
    }

    const ColorBar = () => {

        const colorOptions = [
            "primary",
            "secondary",
            "contrast",
            "background",
            "accent",
            "h1",
            "h2",
            "h3",
            "body",
            "slider"
        ];

        console.log("op", colorOptions);

        return (
            <>
                <Stack direction='row'
                    sx={{
                        position: 'sticky',
                        position: '-webkit-sticky',
                        bottom: '100px'
                    }}
                >
                    {colorOptions.map(
                        (d, i) => {

                            const handleColorChange = (rgba) => {
                                let iState = partnerStyle;
                                console.log(rgba, "rgba");
                                iState[previewLightmode ? 'light' : 'dark'][d] = rgba;
                                console.log("istate", iState[previewLightmode ? 'light' : 'dark'][d]);
                                setPartnerStyle(iState);
                                const is = previewLightmode;
                                setpreviewLightmode(!is);
                                setpreviewLightmode(is);
                            }

                            const color = partnerStyle[previewLightmode
                                ? 'light' : 'dark'][d];

                            console.log(color, "color");

                            return <Stack direction='column'>
                                <Typography variant="body1" sx={{ mx: 1 }}>
                                    {d.toString().slice(0, 6)}
                                </Typography>
                                <Box
                                    style={{
                                        margin: "5px",
                                        zIndex: 5,
                                        overflow: 'visible'
                                    }}
                                >
                                    <ColorChoice
                                        handleChange={handleColorChange}
                                        color={color}
                                    />

                                </Box>
                            </Stack>
                                ;
                        }
                    )}</Stack>
            </>

            /*
          <ColorPicker
                 startColor={color}
                 handleColorChange={handleColorChange}
             />
         */
        );
    }

    const toRGBA = (input) => {
        let output = input.slice(4, input.length);
        console.log(output);
        return output;
    }
    const QRRef = useRef();
    const buttonStyle = {
        background: "rgba(0,0,0, .4)",
        color: "rgba(255,255,255,1)",
        border: "2px solid yellow"
    }
    const textFieldStyle = {
        margin: 1
    }
    const fileInputStyle = {
        textTransform: "initial",
        color: "rgba(0,0,50,.8)",
        fontWeight: 700,
        border: "2px solid white",
        margin: 2,
        borderRadius: "4px",
        background: 'rgba(255,200,200,.4)'
    }
    const updateSelection = (index) => {
        console.log("updating selection", index);
        console.log(" selection", getters[index]);

        const temp = getters[index];

        const setter = setters[index];
        setter(!temp);

    }

    const uploadLogo = (e) => {

        e.preventDefault();
        e.target.id === 'logoUpload'
            &&
            setlogo(e.target.files[0]);

        e.target.id === 'logoUploadDark'
            &&
            setlogoDark(e.target.files[0]);
    }

    const generateZip = async () => {
        let zip = new JSZip();
        let partnerFolder = zip.folder("Partners");

        zip.file("order.json", generateJSON(true));
        zip.file("notes.txt", notes);
        partnerFolder.file(`${name ? name : `${params.partner}logo`}.png`, logo, { base64: true });
        partnerFolder.file(`${name ? name : `${params.partner}logo`}DARK.png`, logoDark, { base64: true });

        zip.generateAsync({ type: "blob" }).then((content) => {
            // see FileSaver.js

            saveAs(content, `${name}_order.zip`);
        });
    }

    const fileToUrl = (file) => {
        return URL.createObjectURL(file);
    }
    const FileUploader = () => {
        return (<>
            <Grid item container spacing={2} sx={{ margin: 'auto' }}>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            width: "50%",
                            margin: 'auto',
                            borderRadius: '4px',
                            padding: 1,
                            background: "rgba(255,255,255,.3)",
                            backdropFilter: 'blur(10px)'
                        }}
                    >
                        <Typography variant="h5" sx={{ margin: 'auto' }} >
                            Light Logo
                        </Typography>
                        <Typography variant="body1" sx={{ margin: 'auto' }} >
                            (for light backgrounds)
                        </Typography>
                    </Box>
                    {logo &&

                        <Box
                            sx={{
                                margin: 'auto',
                                marginTop: "20px",
                                background: "rgba(255,255,255,0.4)",
                                backdropFilter: 'blur(8px)'
                            }}
                        >
                            <img style={{
                                padding: 20,
                                margin: 'auto',
                                maxWidth: '250px'
                            }} src={fileToUrl(logo)} />
                        </Box>

                    }

                    <label htmlFor="logoUpload">
                        <input style={{ display: 'none' }} type="file" name="logoUpload" id="logoUpload" onChange={uploadLogo} />
                        <Button
                            sx={{
                                ...fileInputStyle
                            }}
                            variant="outlined"
                            component="span" >
                            Choose PNG Logo
                        </Button>
                    </label>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            width: "50%",
                            margin: 'auto',
                            borderRadius: '4px',
                            padding: 1,
                            background: "rgba(255,255,255,.3)",
                            backdropFilter: 'blur(10px)'
                        }}
                    >
                        <Typography variant="h5" sx={{}} >
                            Dark Logo
                        </Typography>
                        <Typography variant="body1" sx={{}} >
                            (for dark backgrounds)
                        </Typography>

                    </Box>

                    {logoDark &&

                        <Box
                            sx={{
                                margin: 'auto',
                                marginTop: '20px',
                                background: "rgba(0,0,30,0.8)"
                            }}
                        >
                            <img style={{
                                padding: 20,
                                margin: 'auto',
                                maxWidth: '250px'
                            }} src={fileToUrl(logoDark)} />
                        </Box>

                    }
                    <label htmlFor="logoUploadDark">
                        <input style={{ display: 'none' }} type="file" name="logoUploadDark" id="logoUploadDark" onChange={uploadLogo} />
                        <Button
                            sx={{
                                ...fileInputStyle
                            }}
                            variant="outlined"
                            component="span" >
                            Choose PNG Logo
                        </Button>
                    </label>
                </Grid>
            </Grid>
        </>);
    }

    const generateJSON = (makeString) => {
        /*
          "butter": {
            "provider": "1",
            "name": "Butter & Me",
            "tagline": "Power Up Your Everyday",
            "website": "https://www.nynow.com",
            "subtitle": "We've prepared a journey for you",
            "specialLink": "/",
            "email": "NYNOWWinter@Experient-Inc.com",
            "logo": "assets/partners/harris.png",
            "logoDark": "assets/partners/harrisDARK.png",
            "isPremium": "true",
            "instagram": "ny_now",
            "twitter": "nynowmarket",
            "scene": "https://cdn.pixabay.com/photo/2015/03/11/12/31/buildings-668616_960_720.jpg",
            "meditations": [
                "7",
                "1",
                "0"
            ]
        }
        */

        let meditationSelection = getMeditationChoice();

        let jsonTemplate = {
            partners: {
                [params.partner]: {
                    provider: provider ? provider : 0,
                    subPartner: subProvider ? subProvider : false,
                    name: name ? name : params.partner,
                    tagline: tagline ? tagline : "",
                    website: website ? `${website}` : "",
                    subtitle: subtitle ? subtitle : "",
                    specialLink: specialLink ? specialLink : "/",
                    email: email ? email : "",
                    logo: `assets/partners/${name}.png`,
                    logoDark: `assets/partners/${name}DARK.png`,
                    isPremium: isPremium,
                    instagram: instagram ? instagram : "",
                    twitter: twitter ? twitter : "",
                    scene: scene ? scene : "",
                    meditations: meditationSelection,
                    style: partnerStyle
                }
            }
        }

        if (subProvider && config.subPartners[subProvider]) {
            jsonTemplate = {
                ...jsonTemplate,
                subPartners: {
                    [subProvider]: {
                        "name": subProvider,
                        "logo": "",
                        "logoDark": ""
                    }
                }
            }
        }



        console.log(jsonTemplate);
        return makeString ? JSON.stringify(jsonTemplate) : jsonTemplate;
    }

    const MeditationSelector = (props) => {


        const map = config.meditations.map(
            (d, i) => {
                return <MeditationCard
                    key={`${d.name + i}`}
                    border={getters[i] ? "5px solid green" : "0px solid rgba(0,0,0,0)"}
                    select={() => { updateSelection(i); }}
                    isSelected={getters[i]}
                    index={i}
                    meditation={d}
                />
            }
        );
        return (
            <>
                <Grid container >
                    {map}
                </Grid>
            </>
        );

    }

    const DisplayMeditation = (props) => {

        const i = props.index;
        const meditation = props.meditation;
        console.log("1");
        /*
          {
                    "index": "0",
                "header": "Breathe",
                "name": "Breath Awareness",
                "description": "Focus",
                "artist": "Rachael",
                "scene": "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg",
                "file": "https://www.youtube.com/watch?v=4v88kBzcAoo&autoplay=1",
                "animation": "true"
        }
                */


        return (
            <>
                <MeditationCard />
            </>
        );
    }


    const DisplayQRCode = (props) => {

        const QRSize = props.customSize ? props.customSize : 450;
        return (
            <Paper

                sx={{
                    px: 3,
                    py: 2,
                    background: "linear-gradient(45deg, rgba(120,100,255,1), rgba(240,20,255,1))"
                }}
            >
                <div
                    style={{
                        paddingBottom: 2
                    }}
                >
                    {provider > 0
                        ?
                        <img
                            alt="logo"
                            style={{
                                maxWidth: `${QRSize}px`
                            }}
                            src={config.providers[1].logo}
                        />
                        :

                        <img
                            alt="logo"
                            style={{
                                maxWidth: `${QRSize}px`
                            }}
                            src={config.providers[0].logo}
                        />
                    }

                </div>
                <QRCode
                    style={{
                        borderRadius: "5px"
                    }}
                    size={QRSize}
                    value={`https://www.bbinside.vip/#/${params.partner}`} />
            </Paper>
        );
    }

    const GetQRCode = () => {
        console.log("QRCODE:", `https://www.bbinside.vip/#/${params.partner}`);
        return (
            <>
                <Backdrop
                    sx={{
                        background: "rgba(0, 0, 0,.6)",
                        backdropFilter: "blur(4px)",
                        color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    open={largeQR} s

                >
                    <Stack>
                        <div
                            ref={props.customSize ? undefined : QRRef}
                            style={{
                                padding: "5px"
                            }}
                        >
                            <Paper
                                sx={{
                                    px: 3,
                                    py: 2,
                                    background: "linear-gradient(45deg, rgba(120,100,255,1), rgba(240,20,255,1))"
                                }}
                            >
                                <div
                                    style={{
                                        paddingBottom: 2
                                    }}
                                >

                                    <img
                                        alt="logo"
                                        style={{
                                            maxWidth: `${450}px`
                                        }}
                                        src={config.providers[provider].logo}
                                    />


                                </div>
                                <QRCode
                                    style={{
                                        borderRadius: "5px"
                                    }}
                                    size={450}
                                    value={`https://www.bbinside.vip/#/${params.partner}`} />
                            </Paper>
                        </div>
                        <div>
                            <Button
                                sx={
                                    { mt: 2, ...buttonStyle }
                                }
                                onMouseDown={
                                    async () => {
                                        while (QRRef.current === null) {
                                            console.log("NO REF");
                                        }


                                        toPng(QRRef.current)
                                            .then((dataUrl) => {
                                                const link = document.createElement('a');
                                                link.download = `BBQR_${params.partner}.png`;
                                                link.href = dataUrl;
                                                console.log("dataUrl", dataUrl);
                                                console.log("ref", QRRef);
                                                console.log("ref.current", QRRef.current);
                                                link.click();
                                            })
                                            .then(
                                                () => {
                                                    setLargeQR(false);
                                                }
                                            )
                                            .catch((err) => {
                                                console.log(err);
                                            })
                                    }
                                }
                            >
                                Save Image
                            </Button>

                        </div>
                    </Stack>
                </Backdrop>

            </>
        );
    }
    const ProviderSelector = (props) => {

        return (
            <Box sx={{
                width: "210px", margin: 'auto', paddingTop: 3, marginTop: 4,
                border: `2px solid rgba(${props.lightMode ? "0,0,0" : "255,255,255"
                    }, .4)`,
                borderRadius: "4px",
                background: props.lightMode ? "rgba(255,255,255,.2)" : "rgba(0,0,0,.2)"
            }}>
                <Typography
                    sx={{
                        color: props.lightMode ? "rgba(50,50,55,1)" : "rgba(255,100,55,1)"
                    }}
                    variant="body1">
                    Select your branding
                </Typography>
                <ToggleButtonGroup
                    orientation="vertical"
                    value={provider}
                    exclusive
                    onChange={(e, n) => {
                        if (n != null) {
                            console.log("p", provider);
                            console.log("n", n);
                            provider != n && setProvider(n);
                        }

                    }}
                    aria-label="provider-selection"
                    sx={{
                        py: 2
                    }}
                >

                    {
                        config.providers.map((d, i) => {
                            return (
                                < ToggleButton
                                    color="primary"
                                    sx={{
                                        border: provider === i ? "1px solid white" : undefined
                                    }}
                                    value={i} aria-label={"bb"} >

                                    <img alt="logo"
                                        src={
                                            props.lightMode
                                                ?
                                                config.providers[i].logo
                                                :
                                                config.providers[i].logoDark
                                        }
                                        style={{ width: '150px' }} />
                                </ToggleButton>
                            );
                        })
                    }

                </ToggleButtonGroup>
            </Box >
        );
    }

    const QRPrompt = () => {
        return (
            <Typography variant="h6" sx={{ margin: 2 }} >

                <span style={{
                    color: props.lightMode ? "black" : "white",
                    fontSize: "15px"
                }} >
                    <Button
                        sx={
                            buttonStyle
                        }
                        onClick={() => {
                            setLargeQR(true);
                            console.log("openQR");
                        }}
                    >
                        <span
                            style={{
                                verticalAlign: 'baseline',
                                fontWeight: 700
                            }}
                        >
                            Generate QR Code
                        </span>
                    </Button>

                </span>
            </Typography >
        );
    }
    const socialLinks = [

        {
            name: "website",
            icon: Language,
            link: partner.website ? partner.website : undefined,
            isPresent: partner.website ? true : false
        },
        {
            name: "twitter",
            icon: Twitter,
            link: partner.twitter ? `https://www.twitter.com/${partner.twitter}` : undefined,
            isPresent: partner.twitter ? true : false
        },
        {
            name: "instagram",
            icon: Instagram,
            link: partner.instagram ? `https://www.instagram.com/${partner.instagram}` : undefined,
            isPresent: partner.instagram ? true : false
        }

    ]
    return (
        <>
            <div className={"App"}
                style={{ border: "1px solid rgba(0,0,50,1)" }} >

                <Paper
                    elevation={6}
                    sx={{
                        marginTop: 7,
                        paddingTop: 2,
                        background: "rgba(0,0,150,.2)"
                    }}
                >

                    <Typography variant="h2" >

                        <span style={{ color: props.lightMode ? "white" : "rgba(255,100,55,1)" }} >
                            bbinside.vip/#/
                        </span>
                        <span style={{ color: props.lightMode ? "black" : "white" }} >
                            {params.partner}
                        </span>
                    </Typography>
                    <GetQRCode />
                </Paper>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}
                        sx={{
                            margin: 'auto'
                        }}>
                        <ProviderSelector />
                    </Grid>
                    <Grid item sm={12} md={6}
                        sx={{
                            margin: 'auto'
                        }}
                    >
                        <div
                            style={{
                                margin: "50px"
                            }}>
                            <DisplayQRCode
                                customSize={200}
                            />
                        </div>

                        <QRPrompt />
                    </Grid>
                </Grid>

                <Paper
                    sx={{
                        background: `url(${scene ? scene : config.meditations[0].scene})`,
                        backgroundSize: 'cover',
                        minHeight: "300px",
                        marginTop: 7,
                        minWidth: '100%'
                    }}>
                    <Snackbar
                        open={true}
                        autoHideDuration={undefined}
                        message={
                            <>
                                {miniPreview &&
                                    <>

                                        <Box
                                            sx={{
                                                width: '270px',
                                                height: '300px',
                                                overflow: 'hidden',
                                                margin: 'auto',
                                                marginLeft: 1.2,
                                                borderRadius: '5px',
                                                background: partnerStyle[previewLightmode ? 'light' : 'dark'].background
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    bottom: "400px",
                                                    right: "315px",
                                                    width: '900px',
                                                    margin: 'auto',
                                                    transform: 'scale(0.3)',
                                                    background: partnerStyle[previewLightmode ? 'light' : 'dark'].background
                                                }}
                                            >
                                                <Welcome
                                                    previewMode={true}
                                                    enter={undefined}
                                                    lightMode={previewLightmode}
                                                    partner={previewPartner}
                                                    partnerStyle={partnerStyle}
                                                />
                                            </Box>
                                        </Box>


                                    </>
                                }
                                <Button
                                    sx={{
                                        height: miniPreview ? '30px' : '30px',
                                        width: miniPreview ? '40px' : '150px',
                                        margin: 'auto',
                                        marginTop: miniPreview ? '7px' : 'auto',
                                        padding: 0,
                                        textTransform: 'initial',
                                        color: 'white', margin: 'auto',
                                        float: 'right'
                                    }}
                                    onClick={() => setMiniPreview(!miniPreview)}>
                                    {miniPreview ? "-" : "See Preview"}
                                </Button>
                            </>

                        }
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        sx={{
                            position: 'fixed',
                            margin: 'auto',
                            bottom: colorDesignMode ? '200px !important' : '140px !important'
                        }}

                    />



                    < Snackbar
                        open={true}
                        autoHideDuration={undefined}
                        message={
                            colorDesignMode ?
                                <>
                                    < Button
                                        sx={{
                                            height: '10px',
                                            width: '40px',
                                            margin: 0,
                                            padding: 0
                                        }}
                                        onClick={() => {
                                            setColorDesignMode(colorDesignMode ? false : true)
                                        }}> -</Button >
                                    <ColorBar />
                                </>
                                :
                                <Button
                                    sx={{
                                        height: colorDesignMode ? '10px' : '25px',
                                        width: colorDesignMode ? '40px' : '150px',
                                        margin: 'auto',
                                        padding: 0,
                                        textTransform: 'initial',
                                        color: 'white', margin: 'auto',

                                    }}
                                    onClick={() => {
                                        setColorDesignMode(colorDesignMode ? false : true)
                                    }}>Choose Colors</Button>
                        }
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        sx={{
                            position: 'fixed',
                            bottom: '80px !important'
                        }}

                    />
                    < Grid container spacing={2} >
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4"
                                sx={{
                                    color: 'white',
                                    background: "rgba(255,255,255,.2)"
                                }}>
                                Design Your Experience
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* leave empty*/}
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <FileUploader />
                        </Grid>

                        <Grid item xs={12} md={4} sx={{ margin: 'auto', marginBottom: 4 }}>

                            <Typography variant="body1">
                                Premium Branding? <Switch checked={isPremium} onChange={() => setisPremium(isPremium ? false : true)} />
                            </Typography>
                            <ProviderSelector lightMode={true} />
                        </Grid>

                        <Grid item xs={12} sm={12}>

                            <Box

                                sx={{
                                    padding: 5,
                                    backdropFilter: 'blur(4px)',
                                    background: "rgba(255,255,255,.6)"
                                }}>
                                <Grid container item xs={12} sm={12} sx={{
                                    margin: 'auto',
                                    padding: 4,
                                    borderRadius: '10px',
                                    backdropFilter: "blur(4px)",
                                    background: "rgba(255,255,255,.0)"
                                }}>

                                    <Grid item xs={12} md={12}
                                        sx={{
                                            mx: 4
                                        }}
                                    >
                                        <Stack direction={"row"}>
                                            <Box>
                                                <TextField
                                                    sx={{
                                                        ...textFieldStyle
                                                    }}
                                                    id="outlined-name"
                                                    label="Name"
                                                    value={name}
                                                    onChange={e => setname(e.target.value)}
                                                />
                                                <TextField
                                                    sx={{
                                                        ...textFieldStyle
                                                    }}
                                                    id="outlined-twitter"
                                                    label="Twitter"
                                                    value={twitter}
                                                    onChange={e => settwitter(e.target.value)}
                                                />
                                            </Box>
                                            <Box>
                                                <TextField
                                                    sx={{
                                                        ...textFieldStyle
                                                    }}
                                                    id="outlined-email"
                                                    label="Email"
                                                    value={email}
                                                    onChange={e => setemail(e.target.value)}
                                                />
                                                <TextField
                                                    sx={{
                                                        ...textFieldStyle
                                                    }}
                                                    id="outlined-instagram"
                                                    label="Instagram"
                                                    value={instagram}
                                                    onChange={e => setinstagram(e.target.value)}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    minWidth: '250px'
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    sx={{

                                                        ...textFieldStyle,
                                                        width: '80%'
                                                    }}
                                                    id="outlined-website"
                                                    label="Website"
                                                    value={website}
                                                    onChange={e => setwebsite(e.target.value)}
                                                />
                                                {
                                                    subProviderChoice
                                                        ?
                                                        (
                                                            <>
                                                                <TextField
                                                                    sx={{

                                                                        ...textFieldStyle,
                                                                        width: '80%'
                                                                    }}
                                                                    fullWidth
                                                                    id="outlined-name"
                                                                    label="Secondary Partner Name"
                                                                    placeholder="NY Now"
                                                                    value={subProvider}
                                                                    onChange={e => setSubProvider(e.target.value)}
                                                                />
                                                                <Button
                                                                    sx={{
                                                                        m: 'auto',
                                                                        mt: -2,
                                                                        textTransform: 'initial',
                                                                        color: 'rgba(0,0,0,.8)'
                                                                    }}
                                                                    onClick={() => setSubProviderChoice(false)}
                                                                >
                                                                    remove subprovider
                                                                </Button>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <Typography variant="body2"
                                                                    sx={{
                                                                        margin: 'auto'
                                                                    }}>
                                                                    Add a secondary brand?
                                                                </Typography>
                                                                <Switch
                                                                    sx={{
                                                                        margin: 'auto'
                                                                    }}
                                                                    fullWidth
                                                                    checked={subProviderChoice}
                                                                    onChange={
                                                                        () => {
                                                                            subProviderChoice ?
                                                                                setSubProviderChoice(false)
                                                                                : setSubProviderChoice(true)
                                                                        }
                                                                    }
                                                                />

                                                            </>
                                                        )

                                                }
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={12}
                                        sx={{
                                            margin: 4,
                                            px: 2
                                        }}
                                    >
                                        <TextField
                                            id="filled-multiline-static"
                                            label="Notes"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}
                                        sx={{
                                            mx: 4,
                                            padding: 2,
                                            borderRadius: '10px',
                                        }}
                                    >
                                        <TextField

                                            sx={{ my: 2 }}
                                            fullWidth
                                            id="outlined-welcomemessage"
                                            label="Welcome Message"
                                            value={tagline}
                                            placeholder="We've Prepared a Guided Meditation for you"
                                            onChange={e => settagline(e.target.value)}
                                        />
                                        <TextField

                                            sx={{ my: 2 }}
                                            fullWidth
                                            id="outlined-welcomesubtitle"
                                            label="Welcome Subtitle"
                                            value={subtitle}
                                            placeholder="We've Prepared a Guided Meditation for you"
                                            onChange={e => setsubtitle(e.target.value)}
                                        />
                                        <TextField
                                            sx={{ my: 2 }}
                                            fullWidth
                                            id="outlined-welcomescene"
                                            label="Welcome Theme"
                                            value={scene}
                                            onChange={e => setscene(e.target.value)}
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={8}
                                        sx={{
                                            padding: 4,
                                            borderRadius: '10px',
                                        }}
                                    >

                                        <Typography variant="h4"
                                            sx={{

                                                textAlign: 'left'
                                            }}
                                        >
                                            Welcome Preview
                                        </Typography>


                                    </Grid>
                                    <Grid item xs={12} md={4}
                                        sx={{
                                            margin: 'auto',
                                            padding: 4,
                                            borderRadius: '10px',
                                        }}
                                    >
                                        <LightSwitch
                                            partnerStyle={
                                                {
                                                    primary: "rgba(0,0,80,1)"
                                                }
                                            }
                                            lightMode={previewLightmode}
                                            setLightMode={setpreviewLightmode}
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={12}
                                        sx={{
                                            margin: 'auto',
                                        }}
                                    >
                                        {
                                            partnerStyle[previewLightmode
                                                ? 'light' : 'dark'][0]
                                        }

                                        {/*
                                                "primary": "rgba(67,35,120,1)",
                    "secondary": "rgba(0,0,150,.2)",
                    "contrast": "rgba(255,255,255,1)",
                    "background": "#282c34",
                    "accent": "rgba(255,100,55,1)",
                    "h1": "rgba(200,1,200,1)",
                                            "h2": "rgba(200,1,200,1)",
                                              "h3": "rgba(200,1,200,1)",
                                                "body": "rgba(0,0,0,1)",
                                                  "slider": "rgba(120,80,200,1)"
 */}
                                    </Grid>
                                    {/*
                                        !props.superAdmin
                                        &&
                                        <WelcomePanel
                                            enter={undefined}
                                            lightMode={previewLightmode}
                                            partner={previewPartner}

                                            partnerStyle={partnerStyle[previewLightmode ? 'light' : 'dark']}
                                            sx={{
                                                width: '100vw',
                                                minHeight: '400px'
                                            }}

                                        />
                                        */}
                                    {
                                        props.superAdmin || !props.superAdmin
                                        &&
                                        <Grid container item xs={12} sm={12} sx={{
                                            margin: 'auto',
                                            marginTop: '-150px',
                                            borderRadius: '10px',
                                            overflow: 'clip',
                                            backdropFilter: "blur(4px)",
                                            transform: 'scale(.75)'
                                        }}>



                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                    background: partnerStyle[previewLightmode ? 'light' : 'dark'].background
                                                }}
                                            >

                                                <Welcome
                                                    previewMode={true}
                                                    enter={undefined}
                                                    lightMode={previewLightmode}
                                                    partner={previewPartner}
                                                    partnerStyle={partnerStyle}
                                                />
                                            </Box>

                                        </Grid>
                                    }
                                </Grid>

                            </Box>



                        </Grid>

                        <Grid item xs={12}>
                            <MeditationSelector />
                        </Grid>

                        <Grid item xs={12}
                            sx={{
                                backdropFilter: "blur(2px)",
                                margin: 4,
                                background: "rgba(255,255,255,0.2)"
                            }}
                        >
                            <Button
                                onClick={generateZip}
                                sx={{
                                    margin: 'auto',
                                    marginBottom: 2,
                                    ...buttonStyle,
                                    background: "rgba(0,0,0,.8)",
                                    textTransform: 'initial'
                                }}
                            >
                                Place Order
                            </Button>

                        </Grid>
                    </Grid >
                </Paper >

                {/*
                <Paper
                    sx={{
                        background: `url(${scene ? scene : config.meditations[0].scene})`,
                        backgroundSize: 'cover',
                        minHeight: "300px",
                        marginTop: 7,
                        minWidth: '100%'
                    }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4"
                                sx={{
                                    color: 'white',
                                    background: "rgba(255,255,255,.2)"
                                }}>
                                Select Your Meditations
                            </Typography>
                        </Grid>
                        <Grid item xs={false} sm={6}>

                        </Grid>
                        <Grid item xs={12}>
                            <MeditationSelector />
                        </Grid>
                    </Grid>
                </Paper>
               */}

                < Grid container spacing={2} >
                    <Grid item xs={12} sx={{ margin: 3 }}>
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ margin: 'auto' }}>
                        <Stack
                            direction='row'
                            sx={{
                                margin: 'auto',
                                maxWidth: "200px",
                                display: { xs: 'flex', md: 'none' }
                            }}
                        >
                            {socialLinks.map((social) => {
                                if (social.isPresent) {
                                    return (
                                        <Button
                                            key={social.name}
                                            onClick={() => window.open(social.link)
                                            }
                                            sx={{
                                                margin: 'auto',
                                                color: props.lightMode ? "rgba(255,255,255,.8)" : "rgba(0,0,55,.8)",
                                                display: 'block'
                                            }}
                                        >
                                            <social.icon />
                                        </Button>
                                    );
                                }
                                return null;

                            })}
                        </Stack>

                    </Grid>
                    <Grid item xs={12}>
                        <div style={{
                            marginTop: 10,
                            height: '100px'
                        }}>
                            {" "}
                        </div>
                    </Grid>

                </Grid >


                <FooterBar
                    lightMode={false}
                    partner={partner ? partner : config.partners["default"]}
                />
            </div >
        </>
    );
}

export default hot(module)(CreatorView);



