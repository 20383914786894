import App from "./App";

import { useParams } from "react-router-dom";
import { useState } from "react";

function QRView() {
    const params = useParams();
    const [partnerName, setPartnerName] = useState(params.partner);


    return (
        <App partner={params.partner} />
    );
}

export default QRView;


